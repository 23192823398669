import {
    collection,
    getDocs, 
    addDoc, 
    query, 
    where,
    doc,
    getDoc,
    updateDoc,
    deleteDoc 
} from "firebase/firestore";
import { db } from "./firebase";

export const getFirestore = async (name) => await getDocs(collection(db, name));

export const createFirestore = async (name, params) => await addDoc(collection(db, name), params);

export const queryFirestore = async (name, value, is, compare) => await getDocs( query( collection(db, name), where(value, is, compare) ) );

export const queryByIdFirestore = async (name, id) => await getDoc( doc( db, name, id ) );

export const updateByIdFirestore = async (name, id, params) => await updateDoc( doc( db, name, id ), params );

export const deleteByIdFirestore = async (name, id) => await deleteDoc( doc(db, name, id) );