import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './gallery-card1.css'

const GalleryCard1 = (props) => {
  return (
    <div className={`gallery-card1-gallery-card ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="gallery-card1-image"
      />
      <div className="gallery-card1-container">
        <h2 className="gallery-card1-text">{props.title}</h2>
        <span className="gallery-card1-text1">{props.subtitle1}</span>
      </div>
      <div className="gallery-card1-container1">
        <Link to={`/reader/${props.id}`} className="gallery-card1-read button">
          {props.button}
        </Link>
        <Link to={`/configuration/${props.id}`} className="gallery-card1-configure button">
          {props.button1}
        </Link>
      </div>
    </div>
  )
}

GalleryCard1.defaultProps = {
  title: 'Project Title',
  rootClassName: '',
  subtitle1: 'Loyalty | Friendship',
  image_alt: 'image',
  button1: 'Configure',
  button: 'Read Now',
  image_src:
    'https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEyfHxmb3Jlc3R8ZW58MHx8fHwxNjI2MjUxMjg4&ixlib=rb-1.2.1&h=1200',
  id: ''
}

GalleryCard1.propTypes = {
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  subtitle1: PropTypes.string,
  image_alt: PropTypes.string,
  button1: PropTypes.string,
  button: PropTypes.string,
  image_src: PropTypes.string,
  id: PropTypes.string
}

export default GalleryCard1
