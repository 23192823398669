import { FirebaseApp } from "../config/firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useHistory  } from 'react-router-dom';

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import './login.css'

const Login = ({ user }) => {
  const navigate = useHistory();

  useEffect(() => {
    if (!user.uid) {
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(FirebaseApp.auth());
      ui.start("#firebase-auth-container", {
        signInOptions: [
          FirebaseApp.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          FirebaseApp.firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: () => {
            navigate.push('/home')
            return false;
          },
        }
      });
    }
    else {
      navigate.push('/home');
    }
  }, [user]);


  return (
    <div className="login-container">
      <Helmet>
        <title>Login - AI-Storyteller</title>
        <meta property="og:title" content="Login - AI-Storyteller" />
      </Helmet>
      <div className="login-container1">
        <div className="login-container2">
          <h1 className="login-text">
            <span>Log In</span>
            <br></br>
          </h1>
          <div id="firebase-auth-container" />
        </div>
      </div>
    </div>
  )
}

export default Login
