import React from "react";
import { Link } from "react-router-dom";
import './notLogedIn.css';

const NotLogedIn = ()=>{
    return(
      <div className="notLogedIn">
        <div className="box">
        <h2>Are you logged In?</h2>
        <span>Let's get you an account!
        <Link to='/login'>
          <button className="button">
            Enter Now
          </button>
        </Link>
        </span>
        </div>
      </div>
    );
};

export default NotLogedIn;