import React from 'react'
import { Link } from 'react-router-dom'
import { signOut } from "firebase/auth";
import UserProfile from '../configuration/userProfile';
import { useHistory } from 'react-router-dom';

import './nav.css'

const Nav = ({ auth, user, open, setOpen }) => {

  const navigate = useHistory();

  const logOut = async () =>{
    await signOut(auth);
    navigate.push('/');
    setOpen(!open);
  }

  return (
    <div className='nav-container'>
      <div className="nav-container1">
        <header data-thq="thq-navbar" className="nav-navbar-interactive">
          <img
            alt="logo"
            src="https://www.mirai-innovation-lab.com/wp-content/uploads/2019/02/mirai_logo.png"
            className="nav-logo"
            onClick={() => !user.uid ? navigate.push('/') : navigate.push('/home')}
          />
            <div onClick={()=> setOpen(!open)} data-thq="thq-burger-menu" className="nav-burger-menu">
              <svg viewBox="0 0 1024 1024" className="nav-icon">
                <path
                  d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
                  className=""
                ></path>
              </svg>
            </div>
          <div data-thq="thq-mobile-menu" className={`nav-mobile-menu ${open && 'teleport-show thq-show thq-translate-to-default'}`}>
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="nav-nav"
            >
              <div className="nav-container2">
                <img
                  alt="image"
                  src="https://www.mirai-innovation-lab.com/wp-content/uploads/2019/02/mirai_logo.png"
                  className="nav-image"
                />
                <div data-thq="thq-close-menu" onClick={()=> setOpen(!open)} className="nav-menu-close">
                  <svg viewBox="0 0 1024 1024" className="nav-icon2">
                    <path
                      d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                      className=""
                    ></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="nav-nav1"
              >
               <UserProfile user={user} />
                <Link to="/home" className="nav-navlink" onClick={()=> setOpen(!open)}>
                  Home
                </Link>
                <Link to="/configuration" className="nav-navlink1" onClick={()=> setOpen(!open)}>
                  Create New Book
                </Link>
              </nav>
              <div className="nav-container4">
                <div className="nav-container5">
                  <div className="nav-container6">
                    {user.uid && (
                      <button className='nav-login button' onClick={logOut}>
                        Log Out
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  )
}

export default Nav
