import React, { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import GalleryCard1 from '../components/home/gallery-card1';
import './home.css';
import NotLogedIn from '../components/general/notLogedIn';
import NewBook from '../components/home/newBook';
import { queryFirestore } from '../config/firestore';
import { getFile } from '../config/storage';

const Home = ({ user }) => {

  const [userBooks, setUserBooks] = useState([]);

  useEffect(() => {
    const getBooks = async () => {
      const response = await queryFirestore('user-books', 'user', '==', user.uid || '');
      if (response) return setUserBooks(response.docs);
    }
    getBooks();

    return () => setUserBooks([]);
  }, []);

  const getImage = (title)=>{
    getFile(`${user.uid}${title}`)
  }

  if (!user.uid) {
    return (<NotLogedIn />)
  }

  const showBooks = userBooks.map((col) => {
    const data = col.data();
    return (
      <Fragment key={col.id}>
        <GalleryCard1
          image_src={`data:image/png;base64, ${data.bookcover}`}
          rootClassName="rootClassName1"
          title={data.title}
          subtitle1={data.lesson}
          id={col.id}
        />
      </Fragment>
    )
  });

  return (
    <div className="home-container">
      <Helmet>
        <title>Home - AI-Storyteller</title>
        <meta property="og:title" content="Home - AI-Storyteller" />
      </Helmet>
      <div className="home-container1">
        <NewBook />
        <h2 className="home-text">
          <span>Your Stories</span>
          <br></br>
        </h2>
        <div className="home-gallery">
          {showBooks}
        </div>
      </div>
    </div>
  )
}

export default Home
