import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import List from '../components/general/list'
import Input from '../components/general/input'
import NotLogedIn from '../components/general/notLogedIn'
import UserProfile from '../components/configuration/userProfile'
import { queryByIdFirestore } from '../config/firestore'
import { useParams } from 'react-router-dom'
import { storyTelling } from '../config/gpt3'
import Load from '../components/general/load'
import { useHistory } from 'react-router-dom';
import Check from '../components/general/check'
import './configuration.css'

const Configuration = ({ user }) => {

  const navigate = useHistory();

  const [title, setTitle] = useState('');
  const [audience, setAudience] = useState('');
  const [character, setCharacter] = useState('');
  const [scenery, setScenery] = useState('');
  const [lesson, setLesson] = useState('');
  const [load, setLoad] = useState(false);
  const [language, setLanguage] = useState('');
  const [plot, setPlot] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const getBookInformation = async () => {
      const response = await queryByIdFirestore('user-books', id);
      if (response) {
        const book = response.data();
        setTitle(book.title);
        setAudience(book.audience);
        setCharacter(book.character);
        setScenery(book.scenery);
        setLesson(book.lesson);
        setPlot(book.plot);
        setLanguage(book.language);
      }
    }
    if (id) getBookInformation();

    return ()=>{
      setTitle('');
      setAudience('');
      setCharacter('');
      setScenery('');
      setLesson('');
      setPlot('');
      setLanguage('');
    }
  }, [])

  const actionBook = (e) => {
    e.preventDefault();

    const doOnBook = async (id) => {
      const next = await storyTelling(user, id, title, audience, character, scenery, lesson, language, plot, )
      if (next) navigate.push('/home');
    }
    setLoad(true)
    doOnBook(id);
  }

  if (!user.uid) {
    return (<NotLogedIn />)
  }

  return (
    <div className="configuration-container">
      { load && <Load /> }
      <Helmet>
        <title>Configuration - AI-Storyteller</title>
        <meta property="og:title" content="Configuration - AI-Storyteller" />
      </Helmet>
      <div className="configuration-container1">
        <h1 className="configuration-text">Configuration</h1>
      </div>
      <div className="configuration-container2">
        <div className="configuration-container3">
          <UserProfile user={user} />
          <List />
        </div>
        <div className="configuration-container5">
          <form className="configuration-form" onSubmit={actionBook}>
            <div className="configuration-container6">
              <h2 className="configuration-text3">General Configuration</h2>
            </div>
            <div className="configuration-container7">
              <Input text={'Title'} action={(e) => setTitle(e.target.value)} value={title} />
              <Input text={'Audience'} action={(e) => setAudience(e.target.value)} value={audience} />
              <Input text={'Character'} action={(e) => setCharacter(e.target.value)} value={character} />
              <Input text={'Scenery'} action={(e) => setScenery(e.target.value)} value={scenery} />
              <Input text={'Plot'} action={(e) => setPlot(e.target.value)} value={plot} />
              <Input text={'Moral Lesson'} action={(e) => setLesson(e.target.value)} value={lesson} />
              <Input text={'Language'} action={(e) => setLanguage(e.target.value)} value={language} />
              <div className="configuration-container8">
                <button onClick={() => navigate.push('/home')} className="configuration-navlink button">
                  Cancel
                </button>
                <button type='submit' className="configuration-navlink1 button">
                  Create Story
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Configuration
