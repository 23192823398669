import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDWZd7o8NHx5uD_Lyrm7UHEiPG0haSBdrs",
    authDomain: "gpt3-books.firebaseapp.com",
    projectId: "gpt3-books",
    storageBucket: "gpt3-books.appspot.com",
    messagingSenderId: "386166713934",
    appId: "1:386166713934:web:b4a249ea3146b1e987dd1f"
};

// Initialize Firebase
const FirebaseApp = firebase.initializeApp(firebaseConfig);
const db = getFirestore(FirebaseApp);
const storage = getStorage(FirebaseApp);
//const analytics = getAnalytics(FirebaseApp);

export { FirebaseApp, db, storage };