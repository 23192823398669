import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { deleteByIdFirestore } from '../../config/firestore'
import PropTypes from 'prop-types'
import Modal from '../general/modal'
import { useHistory } from 'react-router-dom';
import './reader-template.css'

const ReaderTemplate = (props) => {
  
  const [placeholder, setPlaceholder] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useHistory();
  const index = useRef(0);

  useEffect(()=>{
    const tick = ()=> {
      const current = props.content[index.current];
      setPlaceholder(prev => current !== '.' ? prev + current : prev + current + '<br/><br/>');
      index.current++;
    }
    if (index.current < props.content.length) {
      let addChar = setInterval(tick, 10);
      return () => clearInterval(addChar);
    }
  },[placeholder, props]);

  const deleteBook = async ()=>{
    const response = await deleteByIdFirestore('user-books', props.id);
    navigate.push('/home');
  };

  return (
    <div className="reader-template-container">
      <div className="reader-template-container1">
        <h1 id="bookTile" className="reader-template-text">
          {props.heading}
        </h1>
        <div className='button-container'>
          <Link to="/home" className="reader-template-navlink button">
            {props.button}
          </Link>
          <button className='button errase' onClick={()=> setIsOpen(!modalIsOpen)}>
            Delete
          </button>
          {modalIsOpen && <Modal 
            closeModal={()=> setIsOpen(!modalIsOpen)} 
            title={'Delete Book'}
            message={`You are about to delete ${props.heading}, are you sure to continue?`} 
            action={deleteBook} 
            /> 
          }
        </div>
      </div>
      <div id="bookImage" className="reader-template-container2">
        <img
          alt={props.image_alt}
          src={`data:image/png;base64, ${props.image_src}`}
          className="reader-template-image"
        />
      </div>
      <div id="bookImage" className="reader-template-container3">
        <button
          name="Next"
          type="button"
          className="reader-template-button button"
        >
          <span className="reader-template-text01">
            <span>&lt;</span>
            <br></br>
          </span>
        </button>
        <span className="reader-template-text04">
          <p dangerouslySetInnerHTML={{__html: placeholder}} />
        </span>
        <button
          name="Next"
          type="button"
          className="reader-template-button1 button"
        >
          <span className="reader-template-text10">
            <span>&gt;</span>
            <br></br>
          </span>
        </button>
      </div>
    </div>
  )
}
  ReaderTemplate.defaultProps = {
    button: 'Go Back',
    heading: 'The Pretty Little Polite Dolphin',
    image_alt: 'image',
    image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
    content: '',
    id: ''
  }

  ReaderTemplate.propTypes = {
    button: PropTypes.string,
    heading: PropTypes.string,
    image_alt: PropTypes.string,
    image_src: PropTypes.string,
    content: PropTypes.string,
    id: PropTypes.string
  }

  export default ReaderTemplate
