import React from "react";
import './modal.css'

const Modal = ({ closeModal, title, message, action }) => {
  return (
    <div className="modal">
      <div className="modal-container">
        <div className="modal-header">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
        <div className="modal-body">
          <button className="button configuration-navlink" onClick={closeModal}>Cancel</button>
          <button className="button gallery-card1-read" onClick={action}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;