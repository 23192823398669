import React from 'react';
import { Link } from 'react-router-dom';
import './newBook.css';

const NewBook = () => {
  return (
    <div className='newBook'>
      <h2 className='home-text'>
        <span>Let's Create a New Story </span>
      </h2>
      <div className='container'>
        <Link to='/configuration'>
          <button className='button' >
            +
          </button>
        </Link>
        <h3>Add a New Story</h3>
      </div>
    </div>
  )
}

export default NewBook