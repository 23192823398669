import React from 'react'
import PropTypes from 'prop-types'
import './input.css'

const Input = ({ text, value, action }) => {
  return (
    <div className="input-container">
      <label className="input-text">{text}</label>
      <input
        type="text"
        className="input"
        value={value}
        onChange={action}
        required
      />
    </div>
  )
}

export default Input
