import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import NotLogedIn from '../components/general/notLogedIn'
import { useParams } from 'react-router-dom'
import { queryFirestore } from '../config/firestore'
import ReaderTemplate from '../components/reader/reader-template'
import './reader.css'

const Reader = ({user}) => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [ img, setImg ] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    const getBookContent = async () => {
      const response = await queryFirestore('book-content', 'bookId', '==', id);
      if (response) {
        const book = response.docs[0].data();
        if (book){
          setTitle(book.title);
          setContent(book.content);
          setImg(book.bookcover)
        }
      }
    }
    if (id) getBookContent();

    return () => {
      setTitle('');
      setContent('');
      setImg('');
    }
  }, [])

  if(!user.uid){
    return(<NotLogedIn />)
  }

  return (
    <div className="reader-container">
      <Helmet>
        <title>Reader - AI-Storyteller</title>
        <meta property="og:title" content="Reader - AI-Storyteller" />
      </Helmet>
      <ReaderTemplate heading={title} content={content} image_src={img} id={id} />
    </div>
  )
}

export default Reader
