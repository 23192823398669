import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Configuration from './views/configuration'
import Login from './views/login'
import Reader from './views/reader'
import Info from './views/info'
import Home from './views/home'
import Nav from './components/general/nav'
import Footer from './components/general/footer'
import './style.css'

import { getAuth, onAuthStateChanged } from "firebase/auth";

const App = () => {

  const auth = getAuth();
  const [user, setUser] = useState({});
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else setUser({});
    });
  }, [ auth ]);

  return (
      <Router>
        <div>
          <Nav 
            auth={ auth } 
            user={ user }
            open={ open }
            setOpen={ setOpen }
          />
          <Route component={()=> <Info user={user} />} exact path="/" />
          <Route component={()=> <Configuration user={user} />} exact path="/configuration/:id?" />
          <Route component={()=> <Login user={ user } />} exact path="/login" />
          <Route component={()=> <Reader user={ user } />} exact path="/reader/:id" />
          <Route component={()=> <Home user={user} />} exact path="/home" />
          <Footer rootClassName="footer-root-class-name1" />
        </div>
      </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
