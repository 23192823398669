import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import FeatureCard3 from '../components/info/feature-card3'
import TestimonialCard1 from '../components/info/testimonial-card1'
import './info.css'

const Info = (props) => {
  return (
    <div className="info-container">
      <Helmet>
        <title>AI-Storyteller</title>
        <meta property="og:title" content="AI-Storyteller" />
      </Helmet>
      <div className="info-container1">
        <div id="home" className="info-hero">
          <div className="info-container2">
            <h1 className="info-text">Promote reading through values</h1>
            <span className="info-text01">
              <span>
                Let the power of AI teach a moral lesson to your children using the smartest tools.
              </span>
            </span>
            <div className="info-container3">
              <Link to="/login" className="info-navlink button">
                Enter Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="info-features">
        <h1 className="info-text06">Learn using the power of AI</h1>
        <span className="info-text07">
          <span>
            Learn the power behind the Storyteller creation.
          </span>
          <span></span>
        </span>
        <div className="info-container4">
          <FeatureCard3 rootClassName="rootClassName" title={'AI Learning'} description={'Our AI system is capable to learn about your mother toungue.'}/>
          <FeatureCard3 rootClassName="rootClassName" title={'Valuable Lessons'} description={'The system understand about moral lessons taught by millions of stories before.'} />
          <FeatureCard3 rootClassName="rootClassName" title={'Wide Range'} description={'Through millions of computed parameters, write any story your mind can think of.'} />
        </div>
      </div>
      <div className="info-testimonial">
        <div className="info-container5">
          <h1 className="info-text10">
            <span>Top Tool from experts</span>
            <br></br>
          </h1>
          <div className="info-container6">
            <TestimonialCard1
              picture_src="https://images.unsplash.com/photo-1557053910-d9eadeed1c58?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="rootClassName2"
              quote={'This AI-Storyteller motor we are creating is not coming from Mars to invade us; we are the human-machine in civilisation. It AI is really part of who we are.'}
            />
            <TestimonialCard1 
              rootClassName="rootClassName" 
              quote={'Using a large amount of Internet text data and thousands of books for model training, AI-Storyteller motor can imitate the natural language patterns of humans nearly perfectly. This language model is extremely realistic and is considered the most impressive model as of today.'}
            />
            <TestimonialCard1
              picture_src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="rootClassName1"
              quote={'AI-Storyteller motor is the most profound technology that humanity will ever develop and work on. It is even more profound than fire or electricity or the internet.'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
