import { post } from "./api";
import { createFirestore, updateByIdFirestore, queryFirestore } from "./firestore";
import storeFile from "./storage";

export const storyTelling = async (user, id, title, audience, character, scenery, lesson, language, plot) => {

  let bookId = id;

  const { data } = await post('https://api.openai.com/v1/images/generations', {
    "prompt": `Cover Image for a book that is teaching about ${lesson} with the main character being ${character} and the scenery is ${scenery} for the audience ${audience}`,
    "n": 1,
    "response_format": "b64_json",
    "size": "256x256"
  },
    { "headers": { "Authorization": "Bearer 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" } }
  )

  if (data) {
    const img = data.data[0].b64_json;

    const bookDetails = {
      user: user.uid,
      title: title,
      audience: audience,
      character: character,
      scenery: scenery,
      lesson: lesson,
      bookcover: img,
      plot: plot,
      language: language
    };

    if (!id) {
      const response = await createFirestore('user-books', bookDetails);
      bookId = response.id;
    }
    else {
      await updateByIdFirestore('user-books', bookId, bookDetails);
    }

    const content = await post("https://api.openai.com/v1/completions", {
      "prompt": `I want you to act as a Storyteller. You will come up with entertaining stories that are engaging, imaginative, and captivating for the target audience in only 2048 tokens. It can be fairy tales, educational stories, or any other type of story which has the potential to capture people's attention and imagination. Depending on the target audience, you will use the requested theme, scenery, characters, and moral lesson if any. My first request is the following: Write a story for a ${bookDetails.audience} about a ${bookDetails.character} in a ${bookDetails.scenery}, who learns about ${bookDetails.lesson}, the story should develop the following story plot ${bookDetails.plot}. Translate de story to ${bookDetails.language}: `,
      "model": "text-davinci-003",
      "max_tokens": 2048
    },
      { "headers": { "Authorization": "Bearer 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" } }
    );

    if (content) {
      const bookContent = content.data.choices[0].text;
      const params = {
        bookId: bookId,
        content: bookContent,
        createdDateTime: new Date().toLocaleString(),
        title: bookDetails.title,
        bookcover: img,
        plot: bookDetails.plot,
        language: bookDetails.language
      }
      if (!id) {
        const response = await createFirestore('book-content', params);
        if (response.type) return true
      }
      else {
        const response = await queryFirestore('book-content', 'bookId', '==', bookId);
        if (response.docs) {
          const update = await updateByIdFirestore('book-content', response.docs[0].id, params);
          return true
        }
      }
    }

  }
};