import React from 'react'

import ListItem from './list-item'
import './list.css'

const List = (props) => {
  return (
    <ul className="list-ul list">
      <ListItem Text={ 'Configure Book' }/>
    </ul>
  )
}

export default List
