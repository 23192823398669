import React from "react";

const UserProfile = ({user}) => (
    <div className="configuration-profile">
        <img
            alt="image"
            src={user.photoURL}
            className="configuration-image"
        />
        <div className="configuration-container4">
            <span className="nav-text">{user.displayName}</span>
        </div>
    </div>
)

export default UserProfile