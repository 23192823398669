import React from 'react'

import PropTypes from 'prop-types'

import './list-item.css'

const ListItem = (props) => {
  return (
    <div className="list-item-container">
      <li className="list-item-li list-item">
        <span className="list-item-text">{props.Text}</span>
      </li>
    </div>
  )
}

ListItem.defaultProps = {
  Text: 'Option',
}

ListItem.propTypes = {
  Text: PropTypes.string,
}

export default ListItem
